<template>
  <div>
    <!-- -->
    <b-row>
      <b-col>
        <div style="margin-top:39px">
          <p>
            This is the final email the survey respondants will receive after
            confirming their lens purchase in-store. Click on the preview button
            to view the emailer.
          </p>
        </div>
      </b-col>
    </b-row>
    <div style="height:52px;"></div>
    <b-row>
      <b-col>
        <!-- hard code ahead, previous dev using hard code for the list -->
        <!--- --->
        <b-table-simple
          v-if="afterSalesEmailData && afterSalesEmailData.length > 0"
          id="table-1"
          hover
          small
          caption-top
          responsive
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th
                v-for="data in header"
                :key="data.title"
                :class="isEditContent"
                :style="data.style"
              >
                {{ data.title }}
                <p v-if="data.desc" class="mb-0 th-desc">
                  {{ data.desc }}
                </p>
              </b-th>
            </b-tr>
          </b-thead>
          <!---- body --------------------------------------------- -->
          <b-tbody>
            <b-tr
              v-for="(content, ind) in afterSalesEmailData[0].content"
              :key="content.id"
            >
              <b-td
                :style="{
                  borderTop: ind === 0 ? 'inherit' : 0,
                }"
              >
                <h5 class="color">
                  {{ afterSalesEmailData[0].section[ind].data }}
                </h5>
              </b-td>
              <b-td>
                <Editor
                  v-model="content.data"
                  :is-after-sales-email="true"
                  :is-edit="content.isEdit && ind > 0"
                  :num-index="ind"
                  :num-idx="0"
                />
              </b-td>
              <b-td v-if="ind === 0">
                <FileUpload
                  v-model="afterSalesEmailData[0].otherInformation[1].data"
                  :is-edit="afterSalesEmailData[0].otherInformation[1].isEdit"
                />
              </b-td>
              <b-td v-else />
            </b-tr>
            <!---- ---->
            <b-tr
              v-for="(content, ind) in afterSalesEmailData[1].content"
              :key="ind + 'aa1a'"
            >
              <b-td
                :style="{
                  borderTop: ind === 0 ? '1px solid #d8dbe0' : 0,
                }"
              >
                <h5 class="color">
                  {{ afterSalesEmailData[1].section[ind].data }}
                </h5>
              </b-td>

              <b-td>
                <Editor 
                  v-model="content.data" 
                  :is-edit="content.isEdit" 
                  :is-after-sales-email="true" 
                  :num-index="ind"
                  :num-idx="1"
                />
              </b-td>
              <b-td />
            </b-tr>
            <!---- ---->
            <b-tr
              v-for="(content, ind) in afterSalesEmailData[2].content"
              :key="ind + 'aa1a'"
            >
              <b-td
                :style="{
                  borderTop: ind === 0 ? '1px solid #d8dbe0' : 0,
                }"
              >
                <h5 class="color">
                  {{ afterSalesEmailData[2].section[ind].data }}
                  <b-button v-if="ind === 0" style="background-color: white; border: none;">
                    <div style="margin-top: -5px;">
                      <img
                        :id="`tooltipAfterSalesEmail`"
                        class="tooltip-img"
                        style="border: none; width: 20px; height: 20px"
                        src="@/assets/icons/tooltip.png"
                      />
                    </div>
                    <b-tooltip
                      class="btn-tooltip" :target="`tooltipAfterSalesEmail`" triggers="hover" custom-class="custom-tooltip"
                    >
                      <div class="text-left">
                        From the emailer, when the unsubscribe button is selected, user will remove his/her email from the CSV database.
                      </div>
                    </b-tooltip>
                  </b-button>
                </h5>
              </b-td>
              <b-td>
                <Editor 
                  v-model="content.data" 
                  :is-edit="content.isEdit" 
                  :is-after-sales-email="true" 
                  :num-index="ind"
                  :num-idx="2"
                />
              </b-td>
              <b-td v-if="ind === 1">
                <Editor
                  v-model="afterSalesEmailData[2].otherInformation[ind].data"
                  :type="'email'"
                  :is-edit="afterSalesEmailData[2].otherInformation[ind].isEdit"
                  :is-after-sales-email="true"
                  :num-index="ind"
                  :num-idx="2"
                />
              </b-td>
              <b-td v-else />
            </b-tr>
            <!---- ---->
            <b-tr
              v-for="(content, ind) in afterSalesEmailData[3].content"
              :key="ind + 'aa1a'"
            >
              <b-td
                :style="{
                  borderTop: ind === 0 ? '1px solid #d8dbe0' : 0,
                }"
              >
                <h5 class="color">
                  {{ afterSalesEmailData[3].section[ind].data }}
                </h5>
              </b-td>
              <b-td>
                <Editor 
                  v-model="content.data" 
                  :is-edit="content.isEdit" 
                  :is-after-sales-email="true"
                  :num-index="ind"
                  :num-idx="3"
                />
              </b-td>
              <b-td v-if="ind === 1">
                <Editor
                  v-model="afterSalesEmailData[3].otherInformation[ind].data"
                  :type="'email'"
                  :is-edit="afterSalesEmailData[3].otherInformation[ind].isEdit"
                  :is-after-sales-email="true"
                  :num-index="ind"
                  :num-idx="3"
                />
              </b-td>
              <b-td v-else />
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { fixHeaderTable, randomData } from "../../common/CommonUtils";
import Editor from "./Editor.vue";
import FileUpload from "./FileUpload.vue";

const HEADERS_TABLE = [
  { title: "SECTION", style: "width: 20%", class: "", desc: "" },
  { title: "BUTTON", style: "width: 40%", class: "", desc: "" },
  { title: "OTHER INFORMATION", style: "width: 40%", class: "", desc: ".jpeg or .png files only. Max 1MB. Dimension 1002 X 900px" },
];

export default {
  name: "AfterSalesEmail",
  components: {
    Editor,
    FileUpload,
  },
  data() {
    return {
      header: HEADERS_TABLE,
      curStyle: "color: black",
    };
  },
  computed: {
    afterSalesEmailData: {
      // using reference to store because vue can not detect change if data do not have key
      get: function() {
        let data = this.$store.state.email.afterSalesEmail.afterSalesData;
        const _this = this;
        data = data.map((otherData) => {
          const otherDataClone = otherData;
          otherDataClone.content = otherData.content.map((v) => {
            v["id"] = _this.random();
            return v;
          });
          return otherDataClone;
        });
        return data;
      },
    },
    isEditContent() {
      return this.$store.state.email.afterSalesEmail.editStatus
        ? "edit-th-content"
        : "normal-th-content";
    },
  },
  updated() {
    fixHeaderTable(
      "table-1",
      "header-nav",
      this.$store.state.email.afterSalesEmail.editStatus
    );
  },
  methods: {
    random() {
      return randomData(5, 2);
    },
  },
};
</script>

<style scoped>
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch;
}

.otherData-td {
  border-top: none;
}
.th-desc {
  font-weight: 300;
}
.color {
  color: #006db6;
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;
}

.th-desc {
  font-weight: 300;
}

</style>
