var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('div',{staticStyle:{"margin-top":"39px"}},[_c('p',[_vm._v(" This is the final email the survey respondants will receive after confirming their lens purchase in-store. Click on the preview button to view the emailer. ")])])])],1),_c('div',{staticStyle:{"height":"52px"}}),_c('b-row',[_c('b-col',[(_vm.afterSalesEmailData && _vm.afterSalesEmailData.length > 0)?_c('b-table-simple',{attrs:{"id":"table-1","hover":"","small":"","caption-top":"","responsive":""}},[_c('b-thead',{attrs:{"head-variant":"light"}},[_c('b-tr',_vm._l((_vm.header),function(data){return _c('b-th',{key:data.title,class:_vm.isEditContent,style:(data.style)},[_vm._v(" "+_vm._s(data.title)+" "),(data.desc)?_c('p',{staticClass:"mb-0 th-desc"},[_vm._v(" "+_vm._s(data.desc)+" ")]):_vm._e()])}),1)],1),_c('b-tbody',[_vm._l((_vm.afterSalesEmailData[0].content),function(content,ind){return _c('b-tr',{key:content.id},[_c('b-td',{style:({
                borderTop: ind === 0 ? 'inherit' : 0,
              })},[_c('h5',{staticClass:"color"},[_vm._v(" "+_vm._s(_vm.afterSalesEmailData[0].section[ind].data)+" ")])]),_c('b-td',[_c('Editor',{attrs:{"is-after-sales-email":true,"is-edit":content.isEdit && ind > 0,"num-index":ind,"num-idx":0},model:{value:(content.data),callback:function ($$v) {_vm.$set(content, "data", $$v)},expression:"content.data"}})],1),(ind === 0)?_c('b-td',[_c('FileUpload',{attrs:{"is-edit":_vm.afterSalesEmailData[0].otherInformation[1].isEdit},model:{value:(_vm.afterSalesEmailData[0].otherInformation[1].data),callback:function ($$v) {_vm.$set(_vm.afterSalesEmailData[0].otherInformation[1], "data", $$v)},expression:"afterSalesEmailData[0].otherInformation[1].data"}})],1):_c('b-td')],1)}),_vm._l((_vm.afterSalesEmailData[1].content),function(content,ind){return _c('b-tr',{key:ind + 'aa1a'},[_c('b-td',{style:({
                borderTop: ind === 0 ? '1px solid #d8dbe0' : 0,
              })},[_c('h5',{staticClass:"color"},[_vm._v(" "+_vm._s(_vm.afterSalesEmailData[1].section[ind].data)+" ")])]),_c('b-td',[_c('Editor',{attrs:{"is-edit":content.isEdit,"is-after-sales-email":true,"num-index":ind,"num-idx":1},model:{value:(content.data),callback:function ($$v) {_vm.$set(content, "data", $$v)},expression:"content.data"}})],1),_c('b-td')],1)}),_vm._l((_vm.afterSalesEmailData[2].content),function(content,ind){return _c('b-tr',{key:ind + 'aa1a'},[_c('b-td',{style:({
                borderTop: ind === 0 ? '1px solid #d8dbe0' : 0,
              })},[_c('h5',{staticClass:"color"},[_vm._v(" "+_vm._s(_vm.afterSalesEmailData[2].section[ind].data)+" "),(ind === 0)?_c('b-button',{staticStyle:{"background-color":"white","border":"none"}},[_c('div',{staticStyle:{"margin-top":"-5px"}},[_c('img',{staticClass:"tooltip-img",staticStyle:{"border":"none","width":"20px","height":"20px"},attrs:{"id":"tooltipAfterSalesEmail","src":require("@/assets/icons/tooltip.png")}})]),_c('b-tooltip',{staticClass:"btn-tooltip",attrs:{"target":"tooltipAfterSalesEmail","triggers":"hover","custom-class":"custom-tooltip"}},[_c('div',{staticClass:"text-left"},[_vm._v(" From the emailer, when the unsubscribe button is selected, user will remove his/her email from the CSV database. ")])])],1):_vm._e()],1)]),_c('b-td',[_c('Editor',{attrs:{"is-edit":content.isEdit,"is-after-sales-email":true,"num-index":ind,"num-idx":2},model:{value:(content.data),callback:function ($$v) {_vm.$set(content, "data", $$v)},expression:"content.data"}})],1),(ind === 1)?_c('b-td',[_c('Editor',{attrs:{"type":'email',"is-edit":_vm.afterSalesEmailData[2].otherInformation[ind].isEdit,"is-after-sales-email":true,"num-index":ind,"num-idx":2},model:{value:(_vm.afterSalesEmailData[2].otherInformation[ind].data),callback:function ($$v) {_vm.$set(_vm.afterSalesEmailData[2].otherInformation[ind], "data", $$v)},expression:"afterSalesEmailData[2].otherInformation[ind].data"}})],1):_c('b-td')],1)}),_vm._l((_vm.afterSalesEmailData[3].content),function(content,ind){return _c('b-tr',{key:ind + 'aa1a'},[_c('b-td',{style:({
                borderTop: ind === 0 ? '1px solid #d8dbe0' : 0,
              })},[_c('h5',{staticClass:"color"},[_vm._v(" "+_vm._s(_vm.afterSalesEmailData[3].section[ind].data)+" ")])]),_c('b-td',[_c('Editor',{attrs:{"is-edit":content.isEdit,"is-after-sales-email":true,"num-index":ind,"num-idx":3},model:{value:(content.data),callback:function ($$v) {_vm.$set(content, "data", $$v)},expression:"content.data"}})],1),(ind === 1)?_c('b-td',[_c('Editor',{attrs:{"type":'email',"is-edit":_vm.afterSalesEmailData[3].otherInformation[ind].isEdit,"is-after-sales-email":true,"num-index":ind,"num-idx":3},model:{value:(_vm.afterSalesEmailData[3].otherInformation[ind].data),callback:function ($$v) {_vm.$set(_vm.afterSalesEmailData[3].otherInformation[ind], "data", $$v)},expression:"afterSalesEmailData[3].otherInformation[ind].data"}})],1):_c('b-td')],1)})],2)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }